import './styles.scss';

import React, {ChangeEvent, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';

import {ColorModal, ImageCropperModal, PriceModal, VatModal} from '../../../components/ProductEditModals';
import HTMLAndTextEditor from '../../../components/HTMLAndTextEditor';
import ProductTypeSelect from '../../../components/ProductTypeSelect';
import {NavLink} from 'react-router-dom';
import {DEF_VATS, formatToStringView, resetEditProduct, setProductString} from '../slice';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import CommonInput from "../../../components/CommonInput";

const Type = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const product = useAppSelector((state) => state.product.product!);
    const [isPriceModalShown, showPriceModal] = useState<boolean>(false);
    const [isVatModalShown, showVatModal] = useState<boolean>(false);
    const [isImageModalShown, showImageModal] = useState<boolean>(false);
    const [isColorModalShown, showColorModal] = useState<boolean>(false);
    const [imageString, setImageString] = useState<string | ArrayBuffer | null>(null);
    const defPrices = useMemo(() => (product.prices || '').split('|').filter(Boolean), [product.prices]);

    const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = (e.target.files || [])[0];

        if (!file || !file.type.match('image.*')) {
            return;
        }

        const reader = new FileReader();

        if (file && file.type.match('image.*')) {
            //product.image = file.name;
            reader.readAsDataURL(file);
        }

        reader.onload = async () => {
            setImageString(reader.result);
        };
        showImageModal(true);
    };

    const onResetProduct = async () => {
        dispatch(resetEditProduct());
    };

    return (
        <div className="lift-body">
            {!isImageModalShown &&
                <>
                    <div className="fix-btn-wrap" style={{top: '110px'}}>
                        <h2 style={{color: '#343a40', marginBottom: '10px'}}>{t('product.chooseType')}</h2>

                        <ProductTypeSelect/>
                    </div>
                    <div className="fix-btn-wrap" style={{bottom: '125px'}}>
                        <button className="main-button cancel-button" onClick={() => showColorModal(!isColorModalShown)}>{t('button.changeColors')}</button>

                        {isColorModalShown && <ColorModal closeModal={() => showColorModal(false)}/>}
                    </div>
                    <div className="fix-btn-wrap">
                        <button className="btn btn-outline-danger btn-rounded aside-btn" onClick={onResetProduct}>{t('button.reset')}</button>
                    </div>
                    <div className="fix-btn-wrap fix-btn-wrap_right">
                        <NavLink className="main-button" to="../thanks">{t('button.next')}</NavLink>
                    </div>
                </>
            }

            <div className="product-edit-page sm-lift-wrap">
                {isImageModalShown && <ImageCropperModal image={imageString as string} closeModal={() => showImageModal(false)}/>}

                <div className={isImageModalShown ? 'sm-lift sm-lift-overflow-hidden' : 'sm-lift'} style={{backgroundImage: `url(${product.image})`}}>
                    {isPriceModalShown && <PriceModal closeModal={() => showPriceModal(false)}/>}

                    <div className="file-input-wrap editable">
                        <input type="file" className="file-input" accept="image/*" onChange={onImageChange}/>
                    </div>

                    <div className="sm-floor sm-floor-product-top" id="landing">

                        <div className="sm-product-panel">
                            {product.type === 'voucher' && <>
                                <div className="sm-product-voucher-wrap">
                                    <div className="sm-product-title editable">
                                        <CommonInput type={'text'} labelAbove={true} required={true}
                                            value={product.name}
                                            pattern="\w+"
                                            disabled={isImageModalShown}
                                            onChange={(e) => dispatch(setProductString({name: e.currentTarget.value}))}
                                        />
                                    </div>

                                    <div className="sm-product-price editable" onClick={() => !isColorModalShown && !isImageModalShown && showPriceModal(true)}>
                                        <button type="button">-0.5</button>
                                        <span>{formatToStringView(product.productPrice)}&nbsp;€</span>
                                        <button type="button">+0.5</button>
                                    </div>

                                    <div className="sm-product-price-comment">{t('product.plusTaxesAndFees')}</div>
                                </div>

                                <div className="sm-product-voucher-wrap editable" onClick={() => !isColorModalShown && !isImageModalShown && showPriceModal(true)}>
                                    <div className="sm-product-prices">
                                        {defPrices.map((i, k) => <button key={k} className={+product.productPrice === +i ? 'selected' : ''}>{formatToStringView(+i)}&nbsp;€</button>)}
                                    </div>
                                </div>

                                <div className="sm-product-buttons">
                                    <button type="button" className="sm-button sm-button-light sm-voucher-button">
                                        {t('payment.bank')}&nbsp;
                                        <span className="sm-button-price">{formatToStringView(product.productPrice)}&nbsp;€</span>
                                    </button>
                                    <button type="button" className="sm-button sm-voucher-button">
                                        {t('payment.mobile')}&nbsp;
                                        <span className="sm-button-price">{formatToStringView(product.totalPrice)}&nbsp;€</span>
                                    </button>
                                </div>
                            </>}

                            {product.type !== 'voucher' && <>
                                <div className="sm-product-title">
                                    <TextareaAutosize
                                        value={product.name}
                                        onChange={(e) => dispatch(setProductString({name: e.currentTarget.value}))}
                                        className="sm-product-text_editable sm-product-title_editable editable"
                                        disabled={isImageModalShown}
                                    />
                                </div>

                                <div className="sm-product-price-wrap editable" onClick={() => !isColorModalShown && !isImageModalShown && showPriceModal(true)}>
                                    <div className="sm-product-price">{formatToStringView(product.totalPrice)} €</div>

                                    {!!product.showServiceFee && <div className="sm-product-price-comment">{t('product.priceIncludesFee')}</div>}
                                </div>

                                <div className="sm-product-buttons sm-order-inner">
                                    <button type="button" className="sm-button">{t('payment.buy')}</button>
                                </div>
                            </>}

                            <div>
                                <div className="sm-next-text">{t('scrollDown')}</div>
                                {!isImageModalShown && <a className="sm-voucher-next" href="#description">
                                    <span className="sm-voucher-next-arrow"/>
                                    <span className="sm-voucher-next-arrow"/>
                                    <span className="sm-voucher-next-arrow"/>
                                </a>}
                            </div>
                        </div>
                    </div>

                    <div className="sm-floor" id="description">
                        <div className="sm-product-panel">
                            <div className="sm-product-description">
                                <HTMLAndTextEditor editedProductField={'description'}/>
                            </div>

                            <div>
                                <div className="sm-product-tax">
                                    <span className="editable" onClick={() => showVatModal(true)}>
                                        {t('product.priceIncludedVat', {vat: DEF_VATS[product.taxClass]})}
                                    </span>
                                    {isVatModalShown && <VatModal closeModal={() => showVatModal(false)}/>}
                                </div>

                                <div className="sm-product-info-box">
                                    <div className="sm-product-info-box-logos">
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-mm"/>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-elisa"/>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-telia"/>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-moi"/>
                                        <span className="sm-product-info-box-logo sm-product-info-box-logo-dna"/>
                                    </div>
                                    {t('product.mobilePaymentNote')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Type;
